<script>
  import { webp } from "../helpers/webp";
  export let panelItems = [];
  export let imageHeight = "400px";
  export let currentImage = null;

  function clicked(i) {
    currentImage = i;
  }
</script>

<style>
  .panel {
    display: grid;
    grid-template-columns: 1fr;
  }

  @screen md {
    .panel {
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    }
  }

  .image-holder {
    position: relative;
    cursor: pointer;
  }

  picture,
  img {
    width: 100%;
    height: var(--image-height);
    object-fit: cover;
  }
</style>

<div class="panel lg:shadow-mgc lg:mb-16">
  {#each panelItems as item (item.id)}
    <div class="image-holder" on:click={clicked(item.id)}>
      <picture style="--image-height:{imageHeight}">
        <source
          srcset={webp(item.url)}
          type="image/webp"
          style="--image-height:{imageHeight}" />
        <source
          srcset={item.url}
          type="image/jpeg"
          style="--image-height:{imageHeight}" />
        <img
          src={item.url}
          alt={item.caption}
          loading="lazy"
          style="--image-height:{imageHeight}" />
      </picture>

    </div>
  {/each}
</div>
