<script>
  export let caption = "Default caption";
  export let id = "";
  export let copy = "";
  export let topclass = "pt-24";
</script>

<h1 {id} class="uppercase text-center w-full text-3xl {topclass} font-extrabold">{caption}</h1>
<p class="w-full text-justify text-xl pb-12 px-8 pt-8">
  {@html copy}
</p>
