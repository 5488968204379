<script>
  import { onMount } from "svelte";
  import { Swipe, SwipeItem } from "svelte-swipe"; // gzipped 3.37 KB
  import { webp } from "../helpers/webp";

  export let lightboxItems = [];
  export let defaultIndex = 0; //start from 0
  export let currentImage = null;

  let autoplay = false;
  let delay = 3000; //ms
  let showIndicators = true;
  let transitionDuration = 500; //ms

  let SwipeComp;
  let key;

  function handleKeydown(event) {
    key = event.key;
    if (key === "ArrowRight") {
      nextSlide();
    }
    if (key === "ArrowLeft") {
      prevSlide();
    }
    if (key === "Escape") {
      closeLightBox();
    }
  }

  function nextSlide() {
    SwipeComp.nextItem();
  }

  function prevSlide() {
    SwipeComp.prevItem();
  }

  function showBackdrop() {
    defaultIndex = currentImage;
    let body = document.getElementsByTagName("body")[0];
    body.classList.add("backdrop-active");
  }

  function closeLightBox() {
    document.getElementsByTagName("body")[0].classList.remove("backdrop-active");
    currentImage = null;
  }

  onMount(showBackdrop);
</script>

<style>
  .backdrop {
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: grid;
    justify-content: center;
    align-content: center;
    overflow: hidden;
    margin-right: 0 !important;
    z-index: 10;
  }

  .swipe-holder {
    height: 90vh;
    width: 90vw;
  }

  img.swiper {
    display: flex;
    height: 90vh;
    margin: 0 auto;
    object-fit: contain;
  }

  #prev,
  #next {
    border: none;
    font-size: 40px;
    position: absolute;
    top: 45%;
    z-index: 52;
    cursor: pointer;
    @apply text-mgc-500;
  }

  #prev {
    left: 5%;
  }

  #next {
    right: 5%;
  }

  .close-button {
    background-color: rgba(0, 0, 0, 0.6);
    @apply absolute z-50 text-white top-0
      right-0 px-2 border border-gray-100 m-4 cursor-pointer flex items-center h-12;
  }

  .close-button > * {
    line-height: 2rem;
    margin-bottom: 0.5rem;
  }

  @screen lg {
    .close-button {
      @apply mx-16;
    }
  }
</style>

<div class="backdrop">
  <div class="swipe-holder">
    <Swipe bind:this={SwipeComp} defaultIndex={currentImage} {showIndicators}>
      {#each lightboxItems as item}
        <SwipeItem>
          <picture>
            <source srcset={webp(item.url)} type="image/webp" class="swiper" />
            <source srcset={item.url} type="image/jpeg" class="swiper" />
            <img src={item.url} alt={item.caption} loading="lazy" class="swiper" />
          </picture>
        </SwipeItem>
      {/each}
    </Swipe>
    <div id="prev" on:click={prevSlide}>◁</div>
    <div id="next" on:click={nextSlide}>▷</div>

    <div class="close-button" on:click={closeLightBox}><span class="text-3xl pt-2">Zatvori</span> <span class="text-primaryColor text-5xl font-extrabold">&times;</span></div>
  </div>
</div>
<svelte:window on:keydown|passive={handleKeydown} />
