const brand = {
  brand: "MGC Studio",
  logo: "/images/brand/logo.png",
  sticky: true,
  adresa: `<p>Matija Grafički Centar d.o.o.</p>
      <p>52400 Poreč • M. Vlašića 26/45</p>
      <p>tel: +385 52 431 436; 493 041</p>
      <p>fax: +385 493 040</p>
      <p>e-mail: info@mgc-design.com</p>
  `,
  firma: `
  <p>Trgovački sud Pazin * MBS 040030065 * MB 03779475</p>
  <p>Temeljni kapital 20.000,00 kn uplaćen u cijelosti</p>
  <p>Poslovni račun: Addiko Bank d.d.</p>
  <p>IBAN: HR47 2500 0091 1011 3962 5</p>
  <p>SWIFT - BIC: HAABHR22</p>
  <p>OIB: 01283994817</p>
  <p><b>PDV IDENTIFIKACIJSKI BROJ HR01283994817</b></p>
  <p>Član uprave: Karmen Jekić</p>
 
  `,
  menuItems: [
    {
      name: "/reklame",
      caption: "Reklame",
    },

    {
      name: "/signalizacija",
      caption: "Signalizacija",
    },

    {
      name: "/cnclaser",
      caption: "CNC/Laser",
    },
    {
      name: "/vozila",
      caption: "Vozila",
    },
    {
      name: "/tisak",
      caption: "Tisak",
    },
    {
      name: "/najam",
      caption: "Najam",
    },
    {
      name: "/onama",
      caption: "O nama",
    },
    {
      name: "/kontakt",
      caption: "Kontakt",
    },
  ],
};
export { brand };
