<script>
  import { Swipe, SwipeItem } from "svelte-swipe"; // gzipped 3.37 KB
  import { webp } from "../helpers/webp";

  export let carouselItems = [];
  export let defaultIndex = 0; //start from 0

  let key;

  function handleKeydown(event) {
    key = event.key;
    if (key === "ArrowRight") {
      nextSlide();
    }
    if (key === "ArrowLeft") {
      prevSlide();
    }
  }

  let autoplay = true;
  let delay = 3000; //ms
  let showIndicators = true;
  let transitionDuration = 500; //ms

  let SwipeComp;

  function nextSlide() {
    SwipeComp.nextItem();
  }

  function prevSlide() {
    SwipeComp.prevItem();
  }
</script>

<style>
  .swipe-holder {
    height: 70vmin;
    max-height: 50vh;
    width: 100%;
    position: relative;
  }

  @screen lg {
    .swipe-holder {
      @apply shadow-mgc;
      @apply my-16;
    }
  }

  picture,
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  #prev,
  #next {
    border: none;
    font-size: 40px;
    position: absolute;
    top: 45%;
    z-index: 2;
    cursor: pointer;
    @apply text-mgc-500;
  }

  #prev {
    left: 5%;
  }

  #next {
    right: 5%;
  }
</style>

<svelte:window on:keydown|passive={handleKeydown} />
<div class="swipe-holder">
  <Swipe bind:this={SwipeComp} {showIndicators} {autoplay} {delay} {transitionDuration} {defaultIndex}>
    {#each carouselItems as item}
      <SwipeItem>
        <picture>
          <source srcset={webp(item.url)} type="image/webp" />
          <source srcset={item.url} type="image/jpeg" />
          <img src={item.url} alt={item.caption} loading="lazy" />
        </picture>
      </SwipeItem>
    {/each}
  </Swipe>
  <!-- <div id="prev" on:click={prevSlide}>◁</div>
    <div id="next" on:click={nextSlide}>▷</div> -->
</div>
