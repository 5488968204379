<script>
  import { brand } from "../brand";
</script>

<style>
  .footer-holder {
    @apply bg-mgcdark-900;
    @apply text-gray-100;
    width: 100%;
  }

  footer {
    display: grid;
    grid-template-columns: 1fr;
    @apply w-full;
    @apply py-12;
  }

  @screen md {
    footer {
      grid-template-columns: 3fr 3fr 2fr;
    }
  }

  img {
    height: 78px;
  }
</style>

<div class="footer-holder">
  <footer class="w-full lg:max-w-screen-lg xl:max-w-screen-xl lg:mx-auto px-8 py-2">
    <div class="left">
      <picture>
        <source srcset="/images/logo-dark.webp" type="image/webp" />
        <source srcset="/images/logo-dark.png" type="image/png" />
        <img src="/images/logo-dark.png" alt="MGC" loading="lazy" />
      </picture>
      <slot />
    </div>

    <div class="pt-4">
      {@html brand.firma}
    </div>

    <div class="right flex flex-col md:items-end md:align-bottom md:self-end">
      <h1 class="text-3xl font-extrabold uppercase pt-12 pb-2 md:pb-8">Pratite nas</h1>
      <div class="flex">
        <a rel="noopener" href="https://www.facebook.com/MGC-Studio-310459032321259/" target="_blank">
          <img src="/images/icons/facebook.svg" alt="Naš fejs" style="width:50px;height:50px;" loading="lazy" />
        </a>

        <a rel="noopener" class="pl-2" href="https://twitter" target="_blank"> <img src="/images/icons/twitter.svg" alt="Naš tviter" style="width:50px;height:50px" loading="lazy" /> </a>

        <a rel="noopener" class="pl-2" href="https://www.instagram.com/matt_ija/" target="_blank">
          <img src="/images/icons/instagram.svg" alt="Naš instagram" style="width:50px;height:50px" loading="lazy" />
        </a>
      </div>
    </div>
  </footer>
</div>
