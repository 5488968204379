<script>
  import ImagePanel from "../components/ImagePanel.svelte";
  import AnchorText from "../components/AnchorText.svelte";
  import LightBox from "../components/LightBox.svelte";

  let folder = "/images/cnclaser";
  let prefix = "mgc-";
  let start = 1301;
  let finish = 1329;
  let panelItems = [];

  for (let index = start; index <= finish; index++) {
    let index_str = "" + index;
    index_str = index_str.padStart(3, "0");
    panelItems.push({ id: index - start, url: folder + "/" + prefix + index_str + ".jpg", caption: start, copy: "" });
  }

  let currentImage = null;
  let caption = "CNC/Laser";
  let copy = `Kombiniranim korištenjem CNC stroja i laserske graverke u mogučnosti smo izraditi predmete raznih materijala boja i oblika kao što su drvo, plexiglas, stadur, alubond, itd. Kombinacijom tih materijala izrađujemo razne promotivne materijale kao npr. pehare, plakete, privjeske, 3D slova za reklame, itd. Nudimo uslugu rezanja i graviranja raznih željenih oblika navedenih materijala, a kao dodatnu uslugu imamo i prodaju raznih vrsta kvalitetnog plexiglasa.`;

  export let currentRoute;
  export let params;
</script>

<AnchorText {caption} {copy} />
<ImagePanel {panelItems} imageHeight="400px" bind:currentImage />
{#if currentImage != null}
  <LightBox lightboxItems={panelItems} bind:currentImage />
{/if}
