<script>
  import ImagePanel from "../components/ImagePanel.svelte";
  import AnchorText from "../components/AnchorText.svelte";
  import LightBox from "../components/LightBox.svelte";

  let folder = "/images/reklame/obicne";
  let prefix = "mgc-";
  let start = 101;
  let finish = 115;
  let panelItems = [];

  for (let index = start; index <= finish; index++) {
    let index_str = "" + index;
    index_str = index_str.padStart(3, "0");
    panelItems.push({ id: index - start, url: folder + "/" + prefix + index_str + ".jpg", caption: start, copy: "" });
  }

  let currentImage = null;
  let caption = "Obične reklame";
  let copy =
    "Izrađujemo reklamne table raznih dimenzija, oblika i materijala ovisno o specifičnim potrebama naručitelja. Table mogu biti izrađene od aluminijskog kompozita, plexiglasa (razne boje ili proziran), forex-a, microonde (šupljikava plastika - iznimno lagana), drva, MDF-a, itd. Naljepnice koje se nanose na podlogu mogu biti printane ili izrezane od PVC folije visoke kvalitete. Printane naljepnice mogu biti plastificirane, lakirane UV lakom, reflektirajuće ovisno o njihovoj primjeni. Zahvaljujući  korištenju CNC stroja za obradu materijala Vaše table ne moraju biti više samo pravokutnog oblika već mogu biti izrezane po nekom specifićnom nacrtu. Vršimo i uslugu montiranja tabli.";

  export let currentRoute;
  export let params;
</script>

<AnchorText {caption} {copy} />
<ImagePanel {panelItems} imageHeight="400px" bind:currentImage />
{#if currentImage != null}
  <LightBox lightboxItems={panelItems} bind:currentImage />
{/if}
