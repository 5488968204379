<script>
  import ImagePanel from "../components/ImagePanel.svelte";
  import AnchorText from "../components/AnchorText.svelte";
  import LightBox from "../components/LightBox.svelte";

  let folder = "/images/vozila";
  let prefix = "mgc-";
  let start = 1401;
  let finish = 1411;
  let panelItems = [];

  for (let index = start; index <= finish; index++) {
    let index_str = "" + index;
    index_str = index_str.padStart(3, "0");
    panelItems.push({ id: index - start, url: folder + "/" + prefix + index_str + ".jpg", caption: start, copy: "" });
  }

  let currentImage = null;
  let caption = "Vozila";
  let copy = `Vaše vozilo možemo kompletno ili djelomično oblijepiti s naljepnicama. Naljepnice koje se koriste su specijalne naljepnice predviđene za ljepljenje po vozilima gdje ima puno zakrivljenih površina, neravnina te utora. Naljepnice se dodatno laminiraju kako bi bile postojanije na UV zračenja i fizička oštećenja. Nudimo uslugu dizajniranja, grafičke pripreme i samog ljepljenja naljepnica na vozilo.`;

  export let currentRoute;
  export let params;
</script>

<AnchorText {caption} {copy} />
<ImagePanel {panelItems} imageHeight="400px" bind:currentImage />
{#if currentImage != null}
  <LightBox lightboxItems={panelItems} bind:currentImage />
{/if}
