<script>
  export let iconPanelItems = [];
  export let rowSize = 3;
  export let showCopy = "true";
  export let componentClass = "";
</script>

<style>
  .panel {
    display: grid;
    grid-template-columns: 1fr;
  }

  @screen md {
    .panel {
      grid-template-columns: repeat(var(--row-size), 1fr);
    }
  }

  img {
    @apply inline;
  }

  h1 {
    @apply font-semibold pt-2 text-2xl;
  }
</style>

<div class="panel bg-secondaryColor lg:shadow-mgc lg:mb-16" style="--row-size:{rowSize} ">
  {#each iconPanelItems as item}
    <div class="text-center w-full px-12 py-8">
      <p><img src={item.url} alt={item.caption} loading="lazy" /></p>
      <h1>{item.caption}</h1>
      <p>
        {#if showCopy === 'true'}
          {@html item.copy}
        {/if}
      </p>
    </div>
  {/each}
</div>
