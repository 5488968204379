<script>
  import Carousel from "../components/Carousel.svelte";
  import NumberPanel from "../components/NumberPanel.svelte";
  import IconPanel from "../components/IconPanel.svelte";
  import Marquee from "../components/Marquee.svelte";

  export let currentRoute;
  export let params;

  let folder = "/images/home";
  let prefix = "mgc-";
  let start = 1;
  let finish = 11;
  let carouselItems = [];

  for (let index = start; index <= finish; index++) {
    let index_str = "" + index;
    index_str = index_str.padStart(3, "0");
    carouselItems.push({ url: folder + "/" + prefix + index_str + ".jpg", caption: start });
  }

  let numberPanelItems = [
    { number: 25, caption: "Godina postojanja", copy: "Lorem", suffix: "+" },
    { number: 1000, caption: "Klijenata", copy: "Lorem", suffix: "+" },
    {
      number: 7500,
      caption: "Uspiješno izvršenih poslova",
      copy: "_copy_",
      suffix: "+",
    },
  ];

  let iconPanelItems = [
    {
      url: "/images/image-panel/chart-up.jpg",
      caption: "Povijest firme",
      copy: "Sa vama smo već 25+ godina.",
    },
    {
      url: "/images/image-panel/handshake.jpg",
      caption: "Zadovoljni klijenti",
      copy: "80% klijenata se vrača.",
    },
    {
      url: "/images/image-panel/thumb-up.jpg",
      caption: "Radovi za klijente",
      copy: "Sve što je moguće, napravit ćemo odmah.<br>Za sve ostalo dajte nam samo malo više vremena.<br><br><i>Slavko Jekić</i>",
    },
  ];

  let marqueeItems = [
    { url: "/images/marquee/re-01.jpg", caption: "Lito" },
    { url: "/images/marquee/re-02.jpg", caption: "Studenac" },
    { url: "/images/marquee/re-03.jpg", caption: "ADAC" },
    { url: "/images/marquee/re-04.jpg", caption: "Hausbrandt" },
    { url: "/images/marquee/re-05.jpg", caption: "Aminess" },
    { url: "/images/marquee/re-06.jpg", caption: "Maistra" },
    { url: "/images/marquee/re-07.jpg", caption: "Valamar" },
    { url: "/images/marquee/re-08.jpg", caption: "Plava laguna" },
    { url: "/images/marquee/re-09.jpg", caption: "Auto Zubak" },
    { url: "/images/marquee/re-10.jpg", caption: "Luxottica" },
    { url: "/images/marquee/re-11.jpg", caption: "Tele2" },
    { url: "/images/marquee/re-12.jpg", caption: "LTB" },
    { url: "/images/marquee/re-13.jpg", caption: "Byblos" },
    { url: "/images/marquee/re-14.jpg", caption: "DanceStar" },
    { url: "/images/marquee/re-15.jpg", caption: "HAK Poreč" },
    { url: "/images/marquee/re-16.jpg", caption: "DanKuchen" },
    { url: "/images/marquee/re-17.jpg", caption: "Gastro Tim" },
    { url: "/images/marquee/re-18.jpg", caption: "Kozlović" },
    { url: "/images/marquee/re-19.jpg", caption: "Tomaz" },
    { url: "/images/marquee/re-20.jpg", caption: "Rossi" },
  ];
</script>

<Carousel {carouselItems} />

<NumberPanel {numberPanelItems} rowSize="3" showCopy="false" />
<IconPanel {iconPanelItems} rowSize="3" />
<Marquee {marqueeItems} />
