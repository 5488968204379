<script>
  import { webp } from "../helpers/webp";
  export let routes = [];
  export let brand = {};
  export let currentRoute;

  let visible = false;

  $: stickyClass = brand.sticky ? "fixed z-10 top-0" : "";

  function menuSelected() {
    visible = false;
  }
</script>

<style>
  nav {
    letter-spacing: -0.5px;
    @apply bg-mgc-500;
    @apply uppercase;
    @apply w-full;
    @apply font-semibold;
  }

  nav img {
    @apply h-12;
  }

  a {
    @apply inline-block no-underline py-4;
  }

  a:hover {
    @apply text-gray-800;
  }

  @screen lg {
    a {
      @apply py-0;
    }
  }

  a.active {
    @apply font-extrabold;
  }
</style>

<nav class={stickyClass}>
  <div class="w-full lg:max-w-screen-lg xl:max-w-screen-xl lg:mx-auto px-8 py-2">
    <div class="flex items-center justify-between flex-wrap w-full">
      <div class="flex items-center flex-shrink-0 mr-6">
        <a class="no-underline hover:text-gray-800 hover:no-underline" href="/">
          {#if brand.logo}
            <picture>
              <source srcset={webp(brand.logo)} type="image/webp" />
              <source srcset={brand.logo} type="image/png" />
              <img src={brand.logo} alt="{brand.brand} logo" loading="lazy" />
            </picture>
          {:else}<span class="text-2xl pl-2">{brand.brand}</span>{/if}
        </a>
      </div>

      <div class="block lg:hidden">
        <button on:click={() => (visible = !visible)} id="nav-toggle" class="flex items-center px-3 border rounded border-gray-600 h-8 align-middle hover:text-gray-800 hover:border-white">
          <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>

      <div class="w-full flex-grow lg:items-center lg:w-auto hidden lg:block pt-2 lg:pt-0" class:hidden={!visible} id="nav-content">
        <ul class="list-reset lg:flex text-right lg:justify-around flex-1 items-center">
          {#each brand.menuItems as item}
            <li><a class:active={currentRoute.name === item.name} href={item.name} on:click={menuSelected}> {item.caption} </a></li>
          {/each}
        </ul>
      </div>
    </div>
  </div>
</nav>
