<script>
  import { brand } from "../brand";
  import AnchorText from "../components/AnchorText.svelte";
  import ContactForm from "../components/ContactForm.svelte";
  import Map from "../components/Map.svelte";

  export let currentRoute;
  export let params;
  let caption = "Kontakt";
  let id = "kontakt";
</script>

<AnchorText {caption} {id} />
<ContactForm postUrl="/sendemail.php">
  {@html brand.adresa}
</ContactForm>
<Map address="https://goo.gl/maps/yqcE7aTece6J3kXS9" imageUrl="/images/brand/map.jpg" />
