<script>
  export let postUrl = "/sendemail.php";
</script>

<style>
  section {
    display: grid;
    grid-template-columns: 1fr;
    @apply px-8 py-16 bg-mgc-500;
    @apply mb-0;
  }

  @screen lg {
    section {
      grid-template-columns: 3fr 2fr;
      grid-gap: 4rem;
      @apply px-24;
      @apply mb-16 shadow-mgc;
    }
  }

  h1 {
    @apply text-2xl;
  }

  form {
    @apply flex flex-col;
  }

  summary > div {
    @apply font-semibold;
  }

  label {
    @apply pt-2 pl-2;
  }

  input {
    @apply p-2;
  }

  label > span {
    @apply text-red-500;
  }

  button {
    @apply w-full bg-black text-white mt-8 p-2;
  }
</style>

<section>
  <form action={postUrl} method="post">
    <h1>Pošalji upit</h1>
    <label for="name"> Ime i prezime <span>*</span> </label>
    <input type="text" id="name" name="name" placeholder="" />
    <label for="email"> Elektronska pošta <span>*</span> </label>
    <input type="email" id="email" name="email" placeholder="" />
    <label for="message"> Poruka <span>*</span> </label>
    <textarea id="message" name="message" cols="30" rows="5" />

    <button>Pošalji upit</button>
  </form>
  <summary class="mt-16 lg:mt-0">
    <h1>Adresa</h1>
    <div class="pt-2">
      <slot />
    </div>
  </summary>
</section>
