import Layout from "./Layout.svelte";
import Home from "./pages/Home.svelte";
import ONama from "./pages/ONama.svelte";
import Reklame from "./pages/Reklame.svelte";
import Signalizacija from "./pages/Signalizacija.svelte";
import CNCLaser from "./pages/CNCLaser.svelte";
import Vozila from "./pages/Vozila.svelte";
import Tisak from "./pages/Tisak.svelte";
import Najam from "./pages/Najam.svelte";
import Kontakt from "./pages/Kontakt.svelte";

const routes = [
  {
    name: "/",
    layout: Layout,
    component: Home,
    caption: "Prva strana",
  },

  {
    name: "/onama",
    layout: Layout,
    component: ONama,
    caption: "O nama",
  },

  {
    name: "/reklame",
    layout: Layout,
    component: Reklame,
    caption: "Reklame",
  },

  {
    name: "/signalizacija",
    layout: Layout,
    component: Signalizacija,
    caption: "Signalizacija",
  },
  {
    name: "/cnclaser",
    layout: Layout,
    component: CNCLaser,
    caption: "CNC/Laser",
  },
  {
    name: "/vozila",
    layout: Layout,
    component: Vozila,
    caption: "Vozila",
  },
  {
    name: "/tisak",
    layout: Layout,
    component: Tisak,
    caption: "Tisak",
  },
  {
    name: "/najam",
    layout: Layout,
    component: Najam,
    caption: "Najam",
  },
  {
    name: "/kontakt",
    layout: Layout,
    component: Kontakt,
    caption: "Kontakt",
  },
];

export { routes };
