<script>
  export let address;
  export let imageUrl;

  function gotoGoogle() {
    window.location = address;
  }
</script>

<style>
  img {
    display: block;
    width: 100%;
    max-height: 40vh;
    object-fit: cover;

    @apply mb-0;

    @screen lg {
      height: 100%;
      @apply mb-16 shadow-mgc;
    }
  }
</style>

<img src={imageUrl} alt="Our location" loading="lazy" on:click={gotoGoogle} />
