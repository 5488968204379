<script>
  import ImagePanel from "../components/ImagePanel.svelte";
  import AnchorText from "../components/AnchorText.svelte";
  import LightBox from "../components/LightBox.svelte";

  let folder = "/images/tisak/digitalni";
  let prefix = "mgc-";
  let start = 501;
  let finish = 509;
  let panelItems = [];

  for (let index = start; index <= finish; index++) {
    let index_str = "" + index;
    index_str = index_str.padStart(3, "0");
    panelItems.push({ id: index - start, url: folder + "/" + prefix + index_str + ".jpg", caption: start, copy: "" });
  }

  let currentImage = null;
  let caption = "Digitalni tisak";
  let copy = `Pojavom strojeva za digitalni tisak stvorili su se uvjeti tiskanja materijala koji ne zahtjevaju duge rokove isporuke i velike tiraže da bi bili isplativi. Klijenti naime sada svoj proizvod mogu dobiti u vrlo kratkom roku i manjim količinama koje su im potrebne, a nudimo i uslugu personaliziranog tiska. Izrađujemo razne materijale koji se koriste u predstavljanju vaše tvrtke kao što su vizitke, memorandumi, kuverte, fascikli, itd. Promotivne materijale dizajniramo i tiskamo u obliku letaka, brošura, kataloga, ulaznica (s numeracijom), prospekata, cjenika itd.`;
  export let currentRoute;
  export let params;
</script>

<AnchorText {caption} {copy} />
<ImagePanel {panelItems} imageHeight="400px" bind:currentImage />
{#if currentImage != null}
  <LightBox lightboxItems={panelItems} bind:currentImage />
{/if}
