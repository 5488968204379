<script>
  import { onMount } from "svelte";
  import { tweened } from "svelte/motion";
  import { cubicOut } from "svelte/easing";

  export let numberPanelItems = [];
  export let rowSize = 3;
  export let showCopy = "true";
  export let componentClass = "bg-mgc-500";

  const progress = tweened(0, {
    duration: 1500,
    easing: cubicOut
  });

  const intersectionObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        progress.set(1);
        observer.unobserve(entry.target);
      }
    });
  });

  function loadObserver() {
    const element = document.getElementById("number-panel");
    intersectionObserver.observe(element);
  }

  onMount(loadObserver);
</script>

<style>
  .panel {
    display: grid;
    grid-template-columns: 1fr;
  }

  @screen md {
    .panel {
      grid-template-columns: repeat(var(--row-size), 1fr);
    }
  }

  h1 {
    @apply font-bold;
    @apply text-6xl;
    @apply pt-2;
  }

  h2 {
    @apply font-semibold;
    @apply text-2xl;
    @apply pb-2;
  }
</style>

<div
  class="panel {componentClass} lg:shadow-mgc lg:mb-16"
  style="--row-size:{rowSize}"
  id="number-panel">

  {#each numberPanelItems as item}
    <div class="text-center w-full px-2 py-8 animate">
      <h1>{Math.round(item.number * $progress)}{item.suffix}</h1>
      <h2>{item.caption}</h2>
      <p>
        {#if showCopy === 'true'}{item.copy}{/if}
      </p>
    </div>
  {/each}
</div>
