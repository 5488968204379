<script>
  import ImagePanel from "../components/ImagePanel.svelte";
  import AnchorText from "../components/AnchorText.svelte";
  import LightBox from "../components/LightBox.svelte";

  let folder = "/images/reklame/svjetlece";
  let prefix = "mgc-";
  let start = 201;
  let finish = 233;
  let panelItems = [];

  for (let index = start; index <= finish; index++) {
    let index_str = "" + index;
    index_str = index_str.padStart(3, "0");
    panelItems.push({ id: index - start, url: folder + "/" + prefix + index_str + ".jpg", caption: start, copy: "" });
  }

  let currentImage = null;
  let caption = "Svjetleće reklame";
  let copy = "";

  export let currentRoute;
  export let params;
</script>

<AnchorText {caption} {copy} />
<ImagePanel {panelItems} imageHeight="400px" bind:currentImage />
{#if currentImage != null}
  <LightBox lightboxItems={panelItems} bind:currentImage />
{/if}
