<script>
  import { webp } from "../helpers/webp";
  export let marqueeItems = [];
</script>

<style>
  .marquee {
    height: 10vmin;
    width: 100%;
    overflow: hidden;
    position: relative;
    @apply my-8;
  }

  .marquee div {
    display: flex;
    width: 200%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    @apply items-center;

    animation: marquee 30s linear infinite;
    animation-delay: 2s;
  }

  .marquee picture {
    float: left;
    height: 100%;
    filter: saturate(0%);

    @apply mx-2 flex items-center;
    vertical-align: center;
  }

  @screen lg {
    .marquee {
      @apply my-24;
    }

    .marquee img,
    .marquee picture {
      @apply mx-4;
    }
  }

  @keyframes marquee {
    0% {
      left: 0;
    }
    50% {
      left: -100%;
    }
    100% {
      left: 0%;
    }
  }
</style>

<div class="marquee">
  <div class="flex">
    {#each marqueeItems as item}
      <picture>
        <source srcset={webp(item.url)} type="image/webp" />
        <source srcset={item.url} type="image/jpeg" />
        <img src={item.url} alt={item.caption} loading="lazy" />
      </picture>
    {/each}
  </div>
</div>
