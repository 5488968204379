<script>
  import ImagePanel from "../components/ImagePanel.svelte";
  import AnchorText from "../components/AnchorText.svelte";
  import LightBox from "../components/LightBox.svelte";

  let folder = "/images/najam";
  let prefix = "mgc-";
  let start = 1701;
  let finish = 1717;
  let panelItems = [];

  for (let index = start; index <= finish; index++) {
    let index_str = "" + index;
    index_str = index_str.padStart(3, "0");
    panelItems.push({ id: index - start, url: folder + "/" + prefix + index_str + ".jpg", caption: start, copy: "" });
  }
  let currentImage = null;
  let caption = "Najam";
  let copy = `Nudimo vam mogućnost ponude naših vlastitih površina za reklamiranje kroz mjesečni ili godišnji najam. Radi se o samostojećim tablama i tablama na stupovima javne rasvjete u centru i okolici Poreča na prometnim pozicijama kao što su šetnice, zaobilaznice i kružni tokovi. Osim toga u ponudi imamo LED display (3 x 2 m) u centru grada (kružni tok kod tržnice) koji omogućava dinamično i efektno reklamiranje pogotovo u večernjim i noćnim satima.`;

  export let currentRoute;
  export let params;
</script>

<AnchorText {caption} {copy} />
<ImagePanel {panelItems} imageHeight="400px" bind:currentImage />
{#if currentImage != null}
  <LightBox lightboxItems={panelItems} bind:currentImage />
{/if}
