<script>
  import { Route } from "svelte-router-spa";
  import { brand } from "./brand";
  import Navigation from "./components/Navigation.svelte";
  import Footer from "./components/Footer.svelte";
  export let currentRoute;
</script>

<style global>
  .back {
    background-image: url(/images/backs/red.jpg);
    background-position: center;
    opacity: 0.8;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: -1;
  }
</style>

<!-- <div class="back" /> -->
<Navigation {brand} {currentRoute} />
<section class="w-full lg:max-w-screen-lg xl:max-w-screen-xl lg:mx-auto lg:px-8 pt-10">
  <Route {currentRoute} />
</section>
<Footer>
  <p class="mt-2 lg:pl-16">
    {@html brand.adresa}
  </p>
</Footer>
