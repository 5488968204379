<script>
  import { Router } from "svelte-router-spa";
  import { routes } from "./routes";

  // if ("serviceWorker" in navigator) {
  //   navigator.serviceWorker.register("/service-worker.js");
  // }

  let options = { gaPageviews: true };
</script>

<div class="app">
  <main>
    <Router {routes} {options} />
  </main>
</div>
